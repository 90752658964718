import React from 'react';
import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import LoaderButton from 'components/Button/LoaderButton';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import { RegisterImplementationProps } from 'constants/types';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import MainContainer from 'components/Container/MainContainer';
const RegisterPaycomApi = ({
  client,
  provider,
  isLoading,
  onSubmit,
  handleClick
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions: JSX.Element[] = [<>
      Ask your Paycom representative for an API SID and token. For more
      information, click{' '}
      <a target="_blank" href="https://developer.tryfinch.com/employer-resources/Paycom" rel="noopener noreferrer">
        here
      </a>
      .
    </>];
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterPaycomApi.tsx">
        To connect your Paycom account, you must create an API token for{' '}
        <strong>{client.name}</strong>.
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterPaycomApi.tsx" />
      <MultiValueForm onSubmit={onSubmit} fieldNames={['SID', 'apiToken']} render={({
      SID,
      apiToken
    }) => <>
            <MainContainer>
              <Input id="SID" value={SID.value} onChange={event => SID.onChange(event.target.value)} invalid={SID.invalid} label="SID" />
              <Input id="apiToken" value={apiToken.value} onChange={event => apiToken.onChange(event.target.value)} invalid={apiToken.invalid} label="API Token" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit">
              Connect
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterPaycomApi.tsx" />
      {handleClick && <SwitchImplementationButton onClick={handleClick}>
          Use a Username and Password instead
        </SwitchImplementationButton>}
    </>;
};
export default RegisterPaycomApi;