import _ from 'lodash';
import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import MainContainer from 'components/Container/MainContainer';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
const RegisterWorkdayCredential = ({
  client,
  provider,
  isLoading,
  onSubmit,
  handleClick
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions: JSX.Element[] = [<>
      Please follow the instructions{' '}
      <a target="_blank" href="https://www.notion.so/tryfinch/Workday-ab3c6e15d2df470a99de5a94c203293b" rel="noopener noreferrer">
        here
      </a>{' '}
      using the information below:
      <li>
        <strong>User Name:</strong>{' '}
        {_.get(client, 'manualConfig.userId') as string}
      </li>
      <li>
        <strong>Email:</strong> {_.get(client, 'manualConfig.email') as string}
      </li>
      <li>
        <strong>Security Group Name:</strong>{' '}
        {_.get(client, 'manualConfig.userId') as string}
      </li>
    </>];
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterWorkdayCredential.tsx">
        To connect your Workday account, you must create a System User and a
        Custom Group Report Endpoint for <strong>{client.name}</strong>.
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterWorkdayCredential.tsx" />
      <MultiValueForm onSubmit={onSubmit} fieldNames={['username', 'password', 'reportUrl']} render={({
      username,
      password,
      reportUrl
    }) => <>
            <MainContainer>
              <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Username" />
              <PasswordInput id="password" value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} label="Password" />
              <PasswordInput id="reportUrl" value={reportUrl.value} onChange={event => reportUrl.onChange(event.target.value)} invalid={reportUrl.invalid} label="Custom Report JSON Endpoint" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit">
              Connect
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterWorkdayCredential.tsx" />
      {handleClick && <SwitchImplementationButton onClick={handleClick}>
          Connect using the Workday API
        </SwitchImplementationButton>}
    </>;
};
export default RegisterWorkdayCredential;