import React from 'react';
import styles from './styles.module.scss';
const WarningIcon = () => {
  return <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg" data-sentry-element="svg" data-sentry-component="WarningIcon" data-sentry-source-file="Warning.tsx">
      <path d="M9.9956 16C10.2069 16 10.3854 15.9285 10.5312 15.7856C10.6771 15.6427 10.75 15.4656 10.75 15.2544C10.75 15.0431 10.6785 14.8646 10.5356 14.7188C10.3927 14.5729 10.2156 14.5 10.0044 14.5C9.79313 14.5 9.61458 14.5715 9.46875 14.7144C9.32292 14.8573 9.25 15.0344 9.25 15.2456C9.25 15.4569 9.32145 15.6354 9.46435 15.7812C9.60727 15.9271 9.78435 16 9.9956 16ZM9.25 13H10.75V8H9.25V13ZM10.0058 20C8.90469 20 7.86806 19.7917 6.89583 19.375C5.92361 18.9583 5.07292 18.3854 4.34375 17.6562C3.61458 16.9271 3.04167 16.0767 2.625 15.105C2.20833 14.1334 2 13.0952 2 11.9905C2 10.8857 2.20833 9.85069 2.625 8.88542C3.04167 7.92014 3.61458 7.07292 4.34375 6.34375C5.07292 5.61458 5.92332 5.04167 6.89496 4.625C7.86661 4.20833 8.90481 4 10.0095 4C11.1143 4 12.1493 4.20833 13.1146 4.625C14.0799 5.04167 14.9271 5.61458 15.6562 6.34375C16.3854 7.07292 16.9583 7.92169 17.375 8.89008C17.7917 9.85849 18 10.8932 18 11.9943C18 13.0953 17.7917 14.1319 17.375 15.1042C16.9583 16.0764 16.3854 16.9271 15.6562 17.6562C14.9271 18.3854 14.0783 18.9583 13.1099 19.375C12.1415 19.7917 11.1068 20 10.0058 20Z" fill="#B54708" data-sentry-element="path" data-sentry-source-file="Warning.tsx" />
    </svg>;
};
const WarningMessage = ({
  children
}: {
  children: React.ReactNode | string;
}) => <div className={`${styles.warning} ${styles.container}`} data-sentry-component="WarningMessage" data-sentry-source-file="Warning.tsx">
    <div className={styles.icon}>
      <WarningIcon data-sentry-element="WarningIcon" data-sentry-source-file="Warning.tsx" />
    </div>
    <div className={styles.message}>
      <div>{children}</div>
    </div>
  </div>;
export default WarningMessage;