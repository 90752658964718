import React from 'react';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import MainContainer from 'components/Container/MainContainer';
import { ResetPasswordOnProviderLink } from 'components/Link/ResetPasswordOnProviderLink';
const RegisterInsperityCredential = ({
  provider,
  isLoading,
  onSubmit,
  handleClick
}: RegisterImplementationProps) => <>
    <MultiValueForm onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => <>
          <MainContainer>
            <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Username" autoFocus />
            <PasswordInput id="password" value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} label="Password" />
            <ResetPasswordOnProviderLink href="https://passportapps.insperity.com/SSO/ForgotPassword" provider={provider.displayName} />
          </MainContainer>
          <LoaderButton isLoading={isLoading} disabled={isLoading} type="submit">
            Connect
          </LoaderButton>
        </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterInsperityCredential.tsx" />
    {handleClick && <SwitchImplementationButton onClick={handleClick}>
        Enter an API Token instead
      </SwitchImplementationButton>}
  </>;
export default RegisterInsperityCredential;