import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { getBackendUrl } from '../../../services/get-backend-url';
import { useMemo, useRef, useState } from 'react';
import pkceChallenge from 'pkce-challenge';

type OAuthState = {
  code: string | null;
  state: string | null;
  error: string | null;
};

export type Pkce = {
  code_verifier: string;
  code_challenge: string;
};

export const useOAuthStatePolling = ({
  sessionKey,
  onCodeReceived,
  setError,
  statusMessages: {
    success:
      successMessage = 'Authentication successful. Completing authorization...',
    timeout:
      timeoutMessage = 'Your authentication has timed out. Please close the popup and try again.',
  } = {},
}: {
  sessionKey: string;
  onCodeReceived: (code: string, pkce: Pkce) => Promise<void>;
  setError: (error: { message: string }) => void;
  statusMessages?: {
    success?: string;
    timeout?: string;
  };
}) => {
  const pkce = useMemo(() => pkceChallenge(128), []);
  const [loading, setLoading] = useState(false);
  const [polling, setPolling] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const pollingTimer = useRef<NodeJS.Timeout>(); // Reference to timer so we can clean it up

  useQuery({
    queryKey: [`oauth_state`, sessionKey],
    queryFn: () =>
      axios
        .get<{ oAuthState?: OAuthState }>(
          `${getBackendUrl()}/auth/oauth/state/${sessionKey}`,
        )
        .then(({ data }) => data),
    enabled: polling,
    onSuccess: async ({ oAuthState }) => {
      if (oAuthState) {
        setPolling(false);
        clearTimeout(pollingTimer.current!);

        if (oAuthState?.error) {
          setError({ message: oAuthState?.error });
          setLoading(false);
          setStatusMessage('');
          return;
        }

        setStatusMessage(successMessage);

        if (oAuthState?.code) {
          try {
            await onCodeReceived(oAuthState.code, pkce);
          } finally {
            setLoading(false);
            setStatusMessage('');
          }
        }
      }
    },
    // Poll OAuth state every second
    refetchInterval: 1000,
  });

  const openOAuthWindow = async (oauthUrl: string) => {
    setLoading(true);
    setPolling(true);
    pollingTimer.current = setTimeout(() => {
      setLoading(false);
      setPolling(false);
      setStatusMessage(timeoutMessage);
    }, 300_000); // Stop polling in 5 minutes

    window.open(oauthUrl, 'popup', 'popup=true');
  };

  return {
    loading,
    openOAuthWindow,
    polling,
    statusMessage,
    pkce,
  };
};
