import React from 'react';
import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import { CodeInput } from 'components/Input/CodeInput';
const SignInAppliedBusinessSolutionsApi = ({
  isLoading,
  onSubmit
}: RegisterImplementationProps) => {
  const instructions: JSX.Element = <>
      <p>
        Enter your 6 digit <strong>Applied Business Solutions</strong> client
        ID.
      </p>
      <p>
        Your account&apos;s connection will not be complete until{' '}
        <strong>Applied Business Solutions</strong> completes its setup. Please
        allow 7 business days for this.
      </p>
    </>;
  return <SignInBase instructions={instructions} divider onSubmit={onSubmit} fieldNames={['companyId']} render={({
    companyId
  }) => {
    return <>
            <MainContainer useNewDesign>
              <CodeInput id="companyId" label="Client ID" inputs={6} invalid={companyId.invalid} value={companyId.value} onChange={event => companyId.onChange(event)} />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !companyId.value} type="submit">
              Connect
            </LoaderButton>
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInAppliedBusinessSolutionsApi" data-sentry-source-file="SignInAppliedBusinessSolutionsApi.tsx" />;
};
export default SignInAppliedBusinessSolutionsApi;