import React from 'react';
import styled from 'styled-components';
import COLORS from 'constants/colors';
export const BaseAvatar = styled.div`
  font-family: Acid Grotesk;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;

  width: 64px;
  height: 64px;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-radius: 50%;
  border: 4px solid ${COLORS.WHITE};
  overflow: hidden;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.FINCH.BLACK};
`;