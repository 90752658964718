import styled from 'styled-components';
import { ImplementationBenefitsMethod } from '@finch-api/common/dist/db';
import Container from 'components/Container/Container';
import Header from 'components/Header/Header';
import payrollProviderToLocalLogosMap from 'constants/payrollProviderToLocalLogosMap';
import { RegisterProviderContainerProps } from 'constants/types';
import ProviderLogo from 'components/Logo/ProviderLogo';
import SignInGuide from 'pages/Authorize/SignIn/SignInGuide';
import AuthFallbackBoundary from '../AuthFallbackBoundary';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ImplementationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;
const RegisterProviderContainer = ({
  client,
  provider,
  products,
  connection,
  hasBenefitsProduct,
  currentStep,
  implementationDetail,
  setUsingAcfFallback,
  handleStateRedirect,
  children,
  sandboxMode,
  setError,
  newContainerDesign // TODO: remove this option once all providers are using the new design
}: RegisterProviderContainerProps) => {
  const eligibleForAssistedBenefits =
  // only eligible for assisted benefits if there's benefits product
  hasBenefitsProduct &&
  // only eligible for assisted benefits if provider supports assisted benefits
  implementationDetail.benefitsMethod === ImplementationBenefitsMethod.ASSISTED &&
  // only eligible for assisted benefits if provider doesn't support accountant flow
  !implementationDetail.accountantSupported &&
  // only eligible when not in sandbox mode
  !sandboxMode &&
  // only eligible when connection's assisted status is not CONNECTED
  connection?.assistedStatus !== AccountStatus.CONNECTED;
  return <Container newContainerDesign={newContainerDesign} data-sentry-element="Container" data-sentry-component="RegisterProviderContainer" data-sentry-source-file="RegisterProviderContainer.tsx">
      <AuthFallbackBoundary client={client} provider={provider} implementationDetail={implementationDetail} setUsingAcfFallback={setUsingAcfFallback} handleStateRedirect={handleStateRedirect} setError={setError} data-sentry-element="AuthFallbackBoundary" data-sentry-source-file="RegisterProviderContainer.tsx">
        <HeaderWrapper data-sentry-element="HeaderWrapper" data-sentry-source-file="RegisterProviderContainer.tsx">
          <ProviderLogo src={payrollProviderToLocalLogosMap[provider.id].largeLogo} alt="" data-sentry-element="ProviderLogo" data-sentry-source-file="RegisterProviderContainer.tsx" />
          <Header data-sentry-element="Header" data-sentry-source-file="RegisterProviderContainer.tsx">Connect your {provider.displayName} account</Header>
        </HeaderWrapper>
        <ImplementationWrapper data-sentry-element="ImplementationWrapper" data-sentry-source-file="RegisterProviderContainer.tsx">
          <SignInGuide client={client} products={products} provider={provider} currentStep={currentStep} enabled={eligibleForAssistedBenefits} data-sentry-element="SignInGuide" data-sentry-source-file="RegisterProviderContainer.tsx">
            {children}
          </SignInGuide>
        </ImplementationWrapper>
      </AuthFallbackBoundary>
    </Container>;
};
export default RegisterProviderContainer;