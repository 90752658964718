import React from 'react';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import { RegisterImplementationProps } from 'constants/types';
import InstructionHeader from '../../../../components/Instruction/InstructionHeader';
import SignInCredentialBase from '../SignInCredentialBase';
const RegisterBobUsername = ({
  provider,
  isLoading,
  onSubmit,
  handleClick,
  sandboxMode
}: RegisterImplementationProps) => <>
    {sandboxMode === 'provider' && <InstructionHeader>
        First make sure to{' '}
        <a href="https://developer.tryfinch.com/docs/documentation/pzie4zvmok31z-provider-sandboxes#bob" target="_blank" rel="noreferrer">
          follow the setup instructions
        </a>{' '}
        to get a test account for Bob.
      </InstructionHeader>}
    <MultiValueForm onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => <SignInCredentialBase username={username} password={password} provider={provider} isLoading={isLoading} pwreset={new URL('https://app.hibob.com/login/reset-password')} disclaimer="A service user and permission group will be added." />} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterBobCredential.tsx" />
    {handleClick && <SwitchImplementationButton onClick={handleClick}>
        Enter an API Token instead
      </SwitchImplementationButton>}
  </>;
export default RegisterBobUsername;