import React from 'react';
import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import MainContainer from 'components/Container/MainContainer';
const RegisterUKGReadyApi = ({
  client,
  provider,
  isLoading,
  onSubmit
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions: JSX.Element[] = [<>
      Please follow the instructions{' '}
      <a target="_blank" href="https://developer.tryfinch.com/employer-resources/UKG-Ready" rel="noopener noreferrer">
        here
      </a>
      . Then, scroll down to continue.
    </>];
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterUKGReadyApi.tsx">
        To connect your UKG Ready account, you must create a service account for{' '}
        <strong>{client.name}</strong>. If you already have a service account,
        you may use that one.
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterUKGReadyApi.tsx" />
      <MultiValueForm onSubmit={onSubmit} fieldNames={['fullDomain', 'username', 'password', 'apiToken', 'clientCode']} render={({
      fullDomain,
      username,
      password,
      apiToken,
      clientCode
    }) => <>
            <MainContainer>
              <Input id="fullDomain" value={fullDomain.value} onChange={event => fullDomain.onChange(event.target.value)} invalid={fullDomain.invalid} label="Web Service URL (example: service4.ultipro.com)" />
              <Input id="username" value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="User Name" />
              <PasswordInput id="password" value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} label="Password" />
              <Input id="apiToken" value={apiToken.value} onChange={event => apiToken.onChange(event.target.value)} invalid={apiToken.invalid} label="API Key" />
              <Input id="clientCode" value={clientCode.value} onChange={event => clientCode.onChange(event.target.value)} invalid={clientCode.invalid} label="Company Short Name" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit">
              Connect
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterUKGReadyApi.tsx" />
    </>;
};
export default RegisterUKGReadyApi;