import React from 'react';
import Header from 'components/Header/Header';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import LoaderButton from 'components/Button/LoaderButton';
import MainContainer from 'components/Container/MainContainer';
import styled from 'styled-components';
import { ProviderConfig } from '@finch-api/common/dist/internal/connect/validate';
const Instructions = styled.div`
  color: var(--Updated-Finch-Black, #161616);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  margin: 0;
  margin-bottom: 24px;
  strong {
    font-weight: 600;
  }
`;
interface SignInManualAdapterProps {
  onNextStep: (fieldToValueMap: Record<string, string>) => void;
  isLoading: boolean;
  contactEmailInContext: string | undefined;
  companyNameInContext: string | undefined;
  provider: ProviderConfig;
}
const SignInManualAdapter = ({
  provider,
  onNextStep,
  contactEmailInContext,
  companyNameInContext,
  isLoading
}: SignInManualAdapterProps) => {
  // only show the field that's not in context
  const fields: string[] = [];
  if (!contactEmailInContext) fields.push('contactEmail');
  if (!companyNameInContext) fields.push('companyName');
  return <>
      <Header data-sentry-element="Header" data-sentry-source-file="SignInManualAdapter.tsx">Connect your {provider.displayName} account</Header>
      <Instructions data-sentry-element="Instructions" data-sentry-source-file="SignInManualAdapter.tsx">
        Please provide your company name and your work email address.
      </Instructions>
      <MultiValueForm onSubmit={onNextStep} fieldNames={fields} render={fields => <>
            <MainContainer useNewDesign>
              {!companyNameInContext && <Input id="companyName" value={fields.companyName.value} onChange={event => fields.companyName.onChange(event.target.value)} invalid={fields.companyName.invalid} label="Company Name" autoFocus />}
              {!contactEmailInContext && <Input id="contactEmail" value={fields.contactEmail.value} onChange={event => fields.contactEmail.onChange(event.target.value)} invalid={fields.contactEmail.invalid} label="Work Email Address" autoFocus={!!companyNameInContext} />}
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading} type="submit">
              Next Step &rarr;
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="SignInManualAdapter.tsx" />
    </>;
};
export default SignInManualAdapter;