import Mfa from 'pages/Authorize/Mfa';
import SelectProvider from 'pages/Authorize/SelectProvider';
import SignIn from 'pages/Authorize/SignIn';
import PermissionList from 'pages/Authorize/Permissions';
import Preamble from 'pages/Authorize/Preamble';
import ChooseAccount from 'pages/Authorize/ChooseAccount';
import ManualSignIn from './ManualSignIn/ManualSignIn';
import ManualCaptcha from 'pages/Authorize/Captcha';

export const step = {
  PREAMBLE: 'PREAMBLE',
  PERMISSIONS: 'PERMISSIONS',
  SELECT_PROVIDER: 'SELECT_PROVIDER',
  SIGN_IN: 'SIGN_IN',
  SIGN_IN_WITH_PASSWORD: 'SIGN_IN_WITH_PASSWORD',
  MFA: 'MFA',
  CHOOSE_ACCOUNT: 'CHOOSE_ACCOUNT',
  MANUAL_SIGN_IN: 'MANUAL_SIGN_IN',
  ASSISTED_BENEFITS: 'ASSISTED_BENEFITS',
  CAPTCHA: 'CAPTCHA',
};

export const stepMap = {
  [step.PREAMBLE]: Preamble,
  [step.PERMISSIONS]: PermissionList,
  [step.SELECT_PROVIDER]: SelectProvider,
  [step.SIGN_IN]: SignIn,
  [step.MFA]: Mfa,
  [step.CHOOSE_ACCOUNT]: ChooseAccount,
  [step.MANUAL_SIGN_IN]: ManualSignIn,
  [step.ASSISTED_BENEFITS]: ManualSignIn,
  [step.CAPTCHA]: ManualCaptcha,
};

export const nextStepMap = {
  [step.PREAMBLE]: step.PERMISSIONS,
  [step.PERMISSIONS]: step.SELECT_PROVIDER,
  [step.SELECT_PROVIDER]: step.SIGN_IN,
  [step.SIGN_IN]: step.MFA,
  [step.MFA]: step.CHOOSE_ACCOUNT,
};

export const nextStepMapReAuth = {
  [step.PREAMBLE]: step.PERMISSIONS,
  [step.PERMISSIONS]: step.SIGN_IN,
  [step.SIGN_IN]: step.MFA,
  [step.MFA]: step.CHOOSE_ACCOUNT,
};

export const prevStepMap = {
  [step.PERMISSIONS]: step.PREAMBLE,
  [step.SELECT_PROVIDER]: step.PERMISSIONS,
  [step.SIGN_IN]: step.SELECT_PROVIDER,
  [step.MANUAL_SIGN_IN]: step.SELECT_PROVIDER,
  [step.MFA]: step.SIGN_IN,
};

export const prevStepMapReAuth = {
  [step.PERMISSIONS]: step.PREAMBLE,
  [step.SIGN_IN]: step.PERMISSIONS,
  [step.MANUAL_SIGN_IN]: step.PERMISSIONS,
  [step.MFA]: step.SIGN_IN,
};
