import React from 'react';
import styled, { keyframes } from 'styled-components';
import shortUUID from 'short-uuid';
import closeXIcon from 'assets/icons/close_icon_red.svg';
import COLORS from 'constants/colors';
import FONTS from 'constants/fonts';
import RedirectLink from 'components/Link/RedirectLink';
import { StateRedirect, ConnectError } from 'pages/Authorize/types';
const slideIn = keyframes`
  0% {
    top: -100px;
  }
  100% {
    top: 20px;
  }
`;
const NotificationsContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: ${FONTS.H5};
  z-index: 9999;
  border: 1px solid ${COLORS.RED.RED_300}};
  background-color: ${COLORS.RED.RED_100};
  color: ${COLORS.RED.RED_600};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  animation: ${slideIn} 0.3s ease-in-out;
`;
const CloseIcon = styled.img`
  cursor: pointer;
  color: ${COLORS.RED.RED_600};
`;
const MessageWrapper = styled.div`
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;
const ErrorCodeWrapper = styled.div`
  font-size: 12px;
  font-weight: bold;
`;
const Code = styled.span`
  font-family: monospace, monospace;
`;
interface NotificationsProps {
  error: ConnectError | null;
  shortSessionKey: shortUUID.SUUID | null;
  providerToRedirect: string | null;
  handleStateRedirect: (redirect: StateRedirect) => void;
  setError: (error: ConnectError | null) => void;
  setProviderToRedirect: React.Dispatch<React.SetStateAction<string | null>>;
}
const Notifications = ({
  error,
  shortSessionKey,
  providerToRedirect,
  handleStateRedirect,
  setError,
  setProviderToRedirect
}: NotificationsProps) => {
  const handleRedirect = () => {
    if (!providerToRedirect) return;
    handleStateRedirect({
      next: 'MANUAL_SIGN_IN',
      nextContext: {
        redirect: true,
        payrollProviderId: providerToRedirect
      }
    });
    setError(null);
    setProviderToRedirect(null);
  };
  const onClose = () => {
    setError(null);
    setProviderToRedirect(null);
  };
  if (!error) return null;
  return <NotificationsContainer data-sentry-element="NotificationsContainer" data-sentry-component="Notifications" data-sentry-source-file="Notifications.tsx">
      <MessageWrapper data-sentry-element="MessageWrapper" data-sentry-source-file="Notifications.tsx">
        <div>
          {error.message}
          {providerToRedirect && <RedirectLink onClick={handleRedirect}>
              Click here to connect.
            </RedirectLink>}
        </div>
        {!error.omitSessionKey && <ErrorCodeWrapper>
            Error Code: <Code> {shortSessionKey}</Code>
          </ErrorCodeWrapper>}
      </MessageWrapper>
      <div>
        <CloseIcon src={closeXIcon} onClick={onClose} data-sentry-element="CloseIcon" data-sentry-source-file="Notifications.tsx" />
      </div>
    </NotificationsContainer>;
};
export default Notifications;