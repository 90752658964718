import React from 'react';
import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import { credentialInstructions } from '../utils';
const SignInQuickbooksCredential = ({
  isLoading,
  onSubmit,
  client,
  provider
}: RegisterImplementationProps) => {
  const instructions = credentialInstructions(client.name);
  return <SignInBase instructions={instructions} onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => {
    return <>
            <MainContainer useNewDesign>
              <Input value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="User ID" id="username" />
              <PasswordInput reset={{
          uri: new URL('https://accounts.intuit.com/app/sign-in?app_group=Identity&asset_alias=Intuit.cto.iam.ius&start_screen_hint=AccountRecovery'),
          providerDisplayName: provider.displayName
        }} value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !username.value || !password.value} type="submit">
              Connect
            </LoaderButton>
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInQuickbooksCredential" data-sentry-source-file="SignInQuickbooksCredential.tsx" />;
};
export default SignInQuickbooksCredential;