import React from 'react';
import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
const SignInCounterpointHcmApi = ({
  provider,
  client,
  isLoading,
  onSubmit
}: RegisterImplementationProps) => {
  const instructionLink = 'https://developer.tryfinch.com/employer-resources/iSolved';
  const instructions = <>
      <p>
        To connect your {provider.displayName} account, you must get your Client
        ID for <strong>{client.name}</strong>.
      </p>
      <p>
        Follow{' '}
        <a href={instructionLink} target="_blank" rel="noopener noreferrer">
          Finch’s instructions
        </a>{' '}
        to complete these steps and then come back here to continue connecting.
      </p>
    </>;
  return <SignInBase instructions={instructions} divider onSubmit={onSubmit} fieldNames={['instructionCheck', 'username']} render={({
    instructionCheck,
    username
  }) => {
    return <>
            <MainContainer useNewDesign>
              <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />
              <Input value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Client ID" id="username" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !instructionCheck.value || !username.value} type="submit">
              Connect
            </LoaderButton>
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInCounterpointHcmApi" data-sentry-source-file="SignInCounterpointHcmApi.tsx" />;
};
export default SignInCounterpointHcmApi;