import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { authorize } from 'services/auth';
import { GoBackButton } from 'components/Button/GoBackButton';
import Container from 'components/Container/Container';
import payrollProviderToLocalLogosMap from 'constants/payrollProviderToLocalLogosMap';
import { withAuthorizeContext, AuthorizeContextValue } from '../AuthorizeContext';
import ProviderLogo from 'components/Logo/ProviderLogo';
import MainContainer from 'components/Container/MainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import { getStatusCode } from '../SignIn/utils';
import { AuthProcessingResponse } from '@finch-api/common/dist/internal/connect/authorize';
import { useAuthFailureCount } from '../AuthFallbackBoundary';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Header from 'components/Header/Header';
const Captcha = ({
  client,
  prevStep,
  providerImplementation,
  payrollProvider,
  setError,
  clientId,
  connectionId,
  clientName,
  redirectUri,
  products,
  sessionKey,
  currentBrowserSessionKey,
  state,
  category,
  sandbox,
  appType,
  implementationHasAssistedBenefits,
  handleAuthorizeResponse,
  setProviderToRedirect,
  mfaCode
}: AuthorizeContextValue) => {
  const {
    authFailureCounts,
    setAuthFailureCounts
  } = useAuthFailureCount();
  if (!client) throw new Error('no client');
  if (!payrollProvider) throw new Error('no payroll provider');
  if (!providerImplementation) throw new Error('no provider implementation');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(undefined);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const onContinue = async () => {
    setLoading(true);
    setError(null);
    try {
      const authorizeResponse = (await authorize({
        clientId,
        connectionId,
        clientName,
        redirectUri,
        products,
        mfaCode,
        sessionKey,
        currentBrowserSessionKey,
        state,
        category,
        step: 'CAPTCHA',
        captchaToken: token,
        payrollProviderId: payrollProvider.id,
        providerImplementationId: providerImplementation.id,
        sandbox,
        appType,
        implementationHasAssistedBenefits
      })) as AuthProcessingResponse;
      handleAuthorizeResponse(authorizeResponse);

      // If we are redirecting, we keep the loading state to avoid double submission.
      // Therefore, we only remove the loading state when there is no redirect.
      if (!('redirect' in authorizeResponse)) {
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      const finchCode = _.get(err, 'response.data.finch_code');
      if (['account_setup_required', 'no_valid_accounts', 'session_expired', 'incorrect_payroll_provider'].includes(finchCode)) {
        setDisableSubmit(true);
      }
      if (finchCode === 'incorrect_payroll_provider') {
        const correctPayrollProvider = _.get(err, 'response.data.correct_payroll_provider');
        setProviderToRedirect(correctPayrollProvider);
      }
      if (err.message === 'Incorrect MFA code. Max attempts reached. Please try again from the beginning.') {
        setError({
          message: err.message
        });
        prevStep();
      }
      setError({
        message: err.message || 'Unexpected error has occurred. Please try again.'
      });
      const errStatusCode = getStatusCode(err);
      if (500 <= errStatusCode && errStatusCode < 600) {
        const failures = authFailureCounts[providerImplementation.id] ?? 0;
        setAuthFailureCounts({
          ...authFailureCounts,
          [providerImplementation.id]: failures + 1
        });
      }
    }
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://captcha-endpoint.paycomonline.net/v4/ee/1/api.js/1/api.js?render=explicit&amp&amp;assethost=https%3A%2F%2Fcaptcha-assethost.paycomonline.net%2Fv4%2Fee&amp;endpoint=https%3A%2F%2Fcaptcha-endpoint.paycomonline.net%2Fv4%2Fee&amp;imghost=https%3A%2F%2Fcaptcha-imghost.paycomonline.net%2Fv4%2Fee&amp;reportapi=https%3A%2F%2Fcaptcha-reportapi.paycomonline.net%2Fv4%2Fee';
    script.async = true;
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      (window as any).hcaptcha.render('captcha', {
        sitekey: '12281f21-d27e-48b9-9faf-f83d141d11e5',
        callback: (token: any) => {
          setToken(token);
        }
      });
    });
  }, []);
  return <Container data-sentry-element="Container" data-sentry-component="Captcha" data-sentry-source-file="Captcha.tsx">
      <GoBackButton onClick={() => prevStep()} data-sentry-element="GoBackButton" data-sentry-source-file="Captcha.tsx" />
      <ProviderLogo src={payrollProviderToLocalLogosMap[payrollProvider.id].largeLogo} alt="" data-sentry-element="ProviderLogo" data-sentry-source-file="Captcha.tsx" />
      <Header data-sentry-element="Header" data-sentry-source-file="Captcha.tsx">Complete the captcha to continue</Header>
      <MultiValueForm formKey={0} onSubmit={onContinue} fieldNames={[]} render={() => <MainContainer>
            <div className="text-center">
              <div id="captcha"></div>
            </div>
            <LoaderButton color={payrollProvider.primaryColor} isLoading={loading} disabled={loading || !token || disableSubmit} type="submit">
              Continue
            </LoaderButton>
          </MainContainer>} data-sentry-element="MultiValueForm" data-sentry-source-file="Captcha.tsx" />
    </Container>;
};
export default withAuthorizeContext(Captcha);