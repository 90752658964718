export const validateSubdomain = (subdomain: string) => {
  if (!subdomain) {
    return false;
  }
  // https://stackoverflow.com/questions/10306690/what-is-a-regular-expression-which-will-match-a-valid-domain-name-without-a-subd/26987741#26987741
  const subdomainRegex = /^((?!-))(xn--)?[a-z0-9][a-z0-9-_]{0,61}[a-z0-9]{0,1}$/;
  return subdomainRegex.exec(subdomain);
};
export const transformScopes = (scopes: string[] | undefined): string[] => {
  if (!scopes) return [];
  return scopes.map(scope => scope.split(':')[1]);
};

// status code might be stored in one of a few spots in a request
export const getStatusCode = (error: any) => {
  return error.response?.data?.statusCode || error.response?.data?.code || error.response?.status;
};
export const apiTokenInstructions = (providerName: string, clientName: string, instructionsLink: string = 'www.tryfinch.com'): JSX.Element => {
  return <p data-sentry-component="apiTokenInstructions" data-sentry-source-file="utils.tsx">
      To connect your {providerName} account, you must create an API token for{' '}
      <strong>{clientName}</strong>.
      <br />
      <br />
      Follow{' '}
      <a href={instructionsLink} target="_blank" rel="noopener noreferrer">
        Finch’s instructions
      </a>{' '}
      to complete these steps and then come back here to continue connecting.
    </p>;
};
export const credentialInstructions = (clientName: string): JSX.Element => {
  return <p data-sentry-component="credentialInstructions" data-sentry-source-file="utils.tsx">
      <strong>{clientName}</strong> will be added as a third-party admin or{' '}
      accountant to retrieve data.
    </p>;
};