import React from 'react';
import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import InstructionsCheckbox from 'components/Input/InstructionsCheckboxInput';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import PasswordInput from 'components/Input/PasswordInput';
import { apiTokenInstructions } from '../utils';
const SignInTriNetApi = ({
  client,
  isLoading,
  onSubmit,
  handleClick,
  provider
}: RegisterImplementationProps) => {
  const instructionsLink = 'https://developer.tryfinch.com/employer-resources/Trinet';
  const instructions = apiTokenInstructions(provider.displayName, client.name, instructionsLink);
  return <SignInBase instructions={instructions} divider onSubmit={onSubmit} fieldNames={['instructionCheck', 'companyId', 'providerClientId', 'providerClientSecret']} render={({
    instructionCheck,
    companyId,
    providerClientId,
    providerClientSecret
  }) => {
    return <>
            <MainContainer useNewDesign>
              <InstructionsCheckbox checked={instructionCheck.value} onChange={checked => instructionCheck.onChange(checked)} />
              <Input value={companyId.value} onChange={event => companyId.onChange(event.target.value)} invalid={companyId.invalid} label="Company ID" id="companyId" />
              <Input value={providerClientId.value} onChange={event => providerClientId.onChange(event.target.value)} invalid={providerClientId.invalid} label="Client ID" id="providerClientId" />
              <PasswordInput value={providerClientSecret.value} onChange={event => providerClientSecret.onChange(event.target.value)} invalid={providerClientSecret.invalid} label="Client Secret" id="providerClientSecret" />
            </MainContainer>
            {handleClick && <SwitchImplementationButton onClick={handleClick} useNewDesign>
                Use a Username and Password instead
              </SwitchImplementationButton>}
            <LoaderButton isLoading={isLoading} disabled={isLoading || !instructionCheck.value || !companyId.value || !providerClientId.value || !providerClientSecret.value} type="submit">
              Connect
            </LoaderButton>
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInTriNetApi" data-sentry-source-file="SignInTriNetApi.tsx" />;
};
export default SignInTriNetApi;