import React, { Component } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  overflow-y: auto;
`;
interface IProps {
  fieldNames: string[];
  onSubmit: (fieldToValueMap: Record<string, string>) => void;
  render: (fieldNames: any) => React.ReactNode;
  formKey?: number;
}
interface IState {
  [key: string]: {
    value: string;
    invalid: boolean;
  };
}
class MultiValueForm extends Component<IProps, IState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    formKey: 0
  };
  constructor(props: IProps) {
    super(props);
    this.state = _.reduce(props.fieldNames, (acc: {
      [key: string]: {
        value: string;
        invalid: boolean;
      };
    }, fieldName: string) => {
      acc[fieldName] = {
        value: '',
        invalid: false
      };
      return acc;
    }, {});
  }
  getOnChange = (fieldName: string) => (value: string) => {
    this.setState({
      [fieldName]: {
        value,
        invalid: false
      }
    });
  };
  isInvalid = (fieldName: string) => {
    const {
      [fieldName]: {
        ...item
      }
    } = this.state;
    return item.value.length === 0;
  };
  handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const {
      fieldNames
    } = this.props;
    const invalidFieldNames = fieldNames.filter(this.isInvalid);
    if (_.isEmpty(invalidFieldNames)) {
      const {
        onSubmit
      } = this.props;
      const fieldToValueMap = _.reduce(fieldNames, (acc: Record<string, string>, fieldName: string) => {
        const {
          [fieldName]: {
            ...item
          }
        } = this.state;
        acc[fieldName] = typeof item.value === 'string' ? item.value.trim() : item.value;
        return acc;
      }, {});
      onSubmit(fieldToValueMap);
    } else {
      this.setState(_.reduce(invalidFieldNames, (acc: {
        [key: string]: {
          value: string;
          invalid: boolean;
        };
      }, fieldName: string) => {
        const {
          [fieldName]: {
            ...item
          }
        } = this.state;
        acc[fieldName] = {
          ...item,
          invalid: true
        };
        return acc;
      }, {}));
    }
  };
  render = () => {
    const {
      render,
      formKey
    } = this.props;
    return <StyledForm key={formKey} onSubmit={this.handleSubmit}>
        {render({
        ..._.mapValues(this.state, (value, key) => ({
          ...value,
          onChange: this.getOnChange(key)
        }))
      })}
      </StyledForm>;
  };
}
export default MultiValueForm;