import React, { ComponentProps } from 'react';
import Checkbox from './CheckboxInput';
import COLORS from 'constants/colors';
import styled from 'styled-components';
type CheckboxProps = ComponentProps<typeof Checkbox>;
type InstructionsCheckboxProps = Partial<Omit<CheckboxProps, 'onChange' | 'checked'>> & Pick<CheckboxProps, 'onChange' | 'checked'>;
const StyledCheckboxContainer = styled.div`
  padding-bottom: 8px;
`;
const InstructionsCheckbox = ({
  label = 'I’ve completed these instructions',
  id = 'instruction-checkbox',
  color = COLORS.FINCH.BLACK,
  ...props
}: InstructionsCheckboxProps): JSX.Element => {
  return <StyledCheckboxContainer data-sentry-element="StyledCheckboxContainer" data-sentry-component="InstructionsCheckbox" data-sentry-source-file="InstructionsCheckboxInput.tsx">
      <Checkbox label={label} id={id} color={color} {...props} data-sentry-element="Checkbox" data-sentry-source-file="InstructionsCheckboxInput.tsx" />
    </StyledCheckboxContainer>;
};
InstructionsCheckbox.defaultProps = {
  label: 'I’ve completed these instructions',
  id: 'instruction-checkbox',
  color: COLORS.FINCH.BLACK
};
export default InstructionsCheckbox;