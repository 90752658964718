import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import COLORS from 'constants/colors';
import FONTS from 'constants/fonts';
import { InputHTMLAttributes } from 'react';
import { snakeCase } from 'lodash';
import { useFlag } from '@unleash/proxy-client-react';
const StyledLabel = styled.label<{
  leftBuffer: number;
}>`
  position: absolute;
  left: ${props => props.leftBuffer || 18}px; // 16px padding + 2px border
  line-height: 130%;
  top: 18px;
  pointer-events: none;
  transition: 0.2s;
  color: ${COLORS.GRAY.GRAY_600};
  font-size: ${FONTS.H5};
  font-family: Inter;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 32px);
`;
interface StyledInputProps extends Exclude<InputHTMLAttributes<HTMLInputElement>, 'placeholder'> {
  invalid: boolean;
  type?: 'text' | 'password';
  startAdornment?: JSX.Element;
  leftBuffer: number;
}
const StyledInput = styled.input<StyledInputProps>`
  width: 100%;
  box-sizing: border-box;
  border: 2px solid ${COLORS.GRAY.GRAY_200};
  ${props => props.invalid && `border-color: ${COLORS.RED.RED_500};`}
  background:  ${COLORS.GRAY.GRAY_200};
  border-radius: 4px;
  transition: border-color 0.2s;
  padding: 24px ${props => props.leftBuffer || 16}px 8px;
  font-size: ${FONTS.H5};
  font-family: Inter;
  color: ${COLORS.GRAY.GRAY_900};

  &:focus {
    outline-color: ${COLORS.FINCH.PURPLE};
    outline-offset: -1px;
  }
`;
const LabeledInputDiv = styled.div`
  padding-bottom: 8px;
  position: relative;
  ${StyledInput}:focus + ${StyledLabel}, ${StyledInput}:valid + ${StyledLabel} {
    top: 8px;
    font-size: ${FONTS.P};
  }
`;
const HelperText = styled.div`
  color: ${COLORS.GRAY.GRAY_600};
  font-size: 12px;
  margin-top: 8px;
`;
export interface InputProps extends Omit<StyledInputProps, 'leftBuffer'> {
  label: string;
  helperText?: string;
}
const AdornmentContainer = ({
  adornment,
  setWidth
}: {
  adornment?: JSX.Element;
  setWidth: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const refContainer = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (refContainer.current) {
      setWidth(refContainer.current.offsetWidth);
    }
  }, [setWidth]);
  return <div ref={refContainer} style={{
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -50%)'
  }} data-sentry-component="AdornmentContainer" data-sentry-source-file="Input.tsx">
      {adornment}
    </div>;
};
const Input = (props: InputProps & {
  wrapperPaddingBottom?: CSSProperties['paddingBottom'];
}) => {
  const {
    label,
    startAdornment,
    helperText,
    ...inputProps
  } = props;
  const [startAdornmentWidth, setStartAdornmentWidth] = useState(0);
  const inputId = inputProps.id || `${snakeCase(label)}-input`;
  const newdesign = useFlag('finchConnectCleanup');
  let style: CSSProperties | undefined = undefined;
  if (newdesign) {
    style = {
      background: '#ffffff',
      border: '1px solid #d9d9d9',
      borderRadius: '8px',
      fontWeight: 400,
      color: '#161616',
      ...(props.style ?? {})
    };
  }
  return <LabeledInputDiv style={{
    paddingBottom: props.wrapperPaddingBottom
  }} data-sentry-element="LabeledInputDiv" data-sentry-component="Input" data-sentry-source-file="Input.tsx">
      <AdornmentContainer adornment={startAdornment} setWidth={setStartAdornmentWidth} data-sentry-element="AdornmentContainer" data-sentry-source-file="Input.tsx" />
      <StyledInput id={inputId} {...inputProps} style={style} leftBuffer={startAdornmentWidth} data-sentry-element="StyledInput" data-sentry-source-file="Input.tsx" />
      <StyledLabel htmlFor={inputId} leftBuffer={startAdornmentWidth} data-sentry-element="StyledLabel" data-sentry-source-file="Input.tsx">
        {label}
      </StyledLabel>
      {helperText && <HelperText>{helperText}</HelperText>}
    </LabeledInputDiv>;
};
Input.defaultProps = {
  type: 'text',
  autoFocus: false,
  disabled: false,
  autoComplete: 'off',
  autoCorrect: 'off',
  spellCheck: 'false',
  required: true
};
export default Input;