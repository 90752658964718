import React from 'react';
import styled from 'styled-components';
import COLORS from 'constants/colors';
import FONTS from 'constants/fonts';
const PwReset = styled.div`
  box-sizing: border-box;
  width: 100%;
  font-size: ${FONTS.P}; // NOTE: (@jordanbrauer) will need to increase with new labels/input font sizes with new designs
  font-family: Inter;
  text-align: right;
  color: ${COLORS.BLACK};
  font-weight: 500;
  padding-bottom: 8px;

  a {
    color: ${COLORS.BLACK};
    text-decoration: none;
  }
`;
export function ResetPasswordOnProviderLink({
  href,
  provider
}: {
  href?: string;
  provider: string;
}): JSX.Element | null {
  if (!href) {
    return null;
  }
  return <PwReset data-sentry-element="PwReset" data-sentry-component="ResetPasswordOnProviderLink" data-sentry-source-file="ResetPasswordOnProviderLink.tsx">
      <a href={href} target="_blank" rel="noreferrer">
        Reset password on {provider}
      </a>
    </PwReset>;
}