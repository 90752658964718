import finchLogoLarge from 'assets/img/finchLogoLarge.svg';
import invalidCircleIcon from 'assets/img/invalidCircle.svg';
import Button from 'components/Button/Button';
import React, { ReactNode } from 'react';
import { ContentContainer, ErrorCode, SandboxContainer, TextGroup, TitleText } from './styles';
const GenericError = ({
  buttonLabel,
  errorCode,
  title,
  children
}: {
  buttonLabel?: string;
  errorCode?: string;
  title: string;
  children: ReactNode;
}) => {
  return <SandboxContainer data-sentry-element="SandboxContainer" data-sentry-component="GenericError" data-sentry-source-file="GenericError.tsx">
      <img alt="finch-logo" src={finchLogoLarge} width={'65px'} />
      <ContentContainer data-sentry-element="ContentContainer" data-sentry-source-file="GenericError.tsx">
        <img alt="invalid-circle" src={invalidCircleIcon} />
        <TextGroup data-sentry-element="TextGroup" data-sentry-source-file="GenericError.tsx">
          <TitleText data-sentry-element="TitleText" data-sentry-source-file="GenericError.tsx">{title}</TitleText>
          <div>{children}</div>
        </TextGroup>
        {errorCode && <ErrorCode>Error Code: {errorCode}</ErrorCode>}
      </ContentContainer>
      {buttonLabel && <Button color={'black'} isLoading={false} disabled={false} onClick={() => {
      window.location.reload();
    }}>
          {buttonLabel}
        </Button>}
    </SandboxContainer>;
};
export default GenericError;