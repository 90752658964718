import React from 'react';
import styled from 'styled-components';
import goBackIcon from 'assets/icons/goBackIcon.svg';
const GoBackIcon = styled.img`
  position: absolute;
  top: 12px;
  left: 16px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  z-index: 1;
`;
export const GoBackButton = ({
  onClick
}: {
  onClick: React.MouseEventHandler<HTMLImageElement>;
}) => {
  return <GoBackIcon onClick={onClick} src={goBackIcon} alt="Go Back" aria-label="Go Back" data-sentry-element="GoBackIcon" data-sentry-component="GoBackButton" data-sentry-source-file="GoBackButton.tsx" />;
};