import React from 'react';
import { useInstructions } from 'components/Instruction/Instructions';
import { RegisterImplementationProps } from 'constants/types';
import SignInApiBase from '../SignInApiBase';
const RegisterHumaansApi = ({
  client,
  provider,
  isLoading,
  onSubmit,
  sandboxMode
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions: JSX.Element[] = [<>
      {' '}
      Using your company&apos;s administrative credentials, sign in to Humaans
      through the{' '}
      <a href="https://app.humaans.io/login">Humaans sign in portal</a>.
    </>, <>
      From Humaans dashboard, open the menu in the top left corner and select{' '}
      <strong>Generate new token</strong>.
    </>, <>
      Generate a token with the following permissions checked:
      <li>View public and private data</li>
      <li>View compensations</li>
      <li>View documents</li>
    </>];
  if (sandboxMode === 'provider') {
    instructions.unshift(<>
        Sign up for a Humaans trial account{' '}
        <a href="https://app.humaans.io/signup" target="_blank" rel="noreferrer">
          on their website
        </a>{' '}
        and sign in
      </>);
  }
  return <SignInApiBase provider={provider} isLoading={isLoading} onSubmit={onSubmit} isAccepted={isAccepted} instructions={instructions} inputPlaceholder="API Key" header={<>
          To connect your Humaans account, you must create an API token for{' '}
          <strong>{client.name}</strong>.
        </>} data-sentry-element="SignInApiBase" data-sentry-component="RegisterHumaansApi" data-sentry-source-file="RegisterHumaansApi.tsx" />;
};
export default RegisterHumaansApi;