import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import search from 'assets/img/search.svg';
import { step } from 'pages/Authorize/steps';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import payrollProviderToLocalLogosMap from 'constants/payrollProviderToLocalLogosMap';
import { isAssistedImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import styles from './SelectProvider.module.scss';
import { AnalyticsEventName, track } from 'utils/analytics';
import { useStagedConfigStore } from '../useStagedConfig';
import { GoBackButton } from 'components/Button/GoBackButton';
import { PayrollProviderConfig } from '@finch-api/common/dist/internal/connect/validate';
import Input from 'components/Input/Input';
const PayrollProviderBlock = styled.div`
  display: flex;
  align-items: center;
`;
const SelectProvider = ({
  setCurrentStep,
  nextStep,
  setPayrollProvider,
  setProviderImplementation,
  currentBrowserSessionKey,
  providersConfig,
  payrollProvider,
  prevStep,
  providerId
}: AuthorizeContextValue) => {
  const stagedConfig = useStagedConfigStore(state => state.stagedConfig);
  const selectProvider = (payrollProvider: PayrollProviderConfig) => {
    setPayrollProvider(payrollProvider);
    track(AnalyticsEventName.SelectedProvider, {
      payrollProviderId: payrollProvider.id,
      currentBrowserSessionKey
    });
    const isManualProvider = payrollProvider.implementations.length === 1 && isAssistedImplementationKind(payrollProvider.implementations[0].kind);
    if (isManualProvider) {
      setCurrentStep(step.MANUAL_SIGN_IN);
      setProviderImplementation(payrollProvider.implementations[0]);
      return;
    }
    nextStep();
  };
  const onClick = (payrollProvider: PayrollProviderConfig) => {
    selectProvider(payrollProvider);
  };

  // Allow user to press the enter/return key to 'click' the payroll provider
  const onEnter = (callback: () => void) => (event: React.KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    callback();
  };
  const [searchInput, setSearchInput] = useState('');
  const onChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setSearchInput(event.target.value);
  };
  const providerConfigList = stagedConfig || providersConfig;
  if (providerConfigList && providerConfigList.length === 1) {
    selectProvider(providerConfigList[0]);
  }
  useEffect(() => {
    if (providerId && payrollProvider) {
      selectProvider(payrollProvider);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // It should be [] same as componentDidMount

  // currently, we can only handle automated and sandbox flow
  // manual flow will be worked on during acf project, and require redesign
  let filteredPayrollProviders = providerConfigList;
  if (searchInput !== '') {
    const lowercaseSearchInput = searchInput.toLowerCase();
    filteredPayrollProviders = _.filter(filteredPayrollProviders, payrollProvider => payrollProvider.displayName.toLowerCase().includes(lowercaseSearchInput));
  }
  const payrollProvidersToDisplay = filteredPayrollProviders && filteredPayrollProviders.length > 0 ? filteredPayrollProviders.map(payrollProvider => <button data-testid="provider" className={styles.payrollProvider} key={payrollProvider.id} onClick={() => onClick(payrollProvider)} onKeyDown={onEnter(() => onClick(payrollProvider))} tabIndex={0}>
          <PayrollProviderBlock>
            <img src={payrollProviderToLocalLogosMap[payrollProvider.id]?.logo} className={styles.logo} alt="" />
            <div>{payrollProvider.displayName}</div>
          </PayrollProviderBlock>
        </button>) : <div className={styles.emptySearchResults}>
        <p>No provider found.</p>
      </div>;
  return <div className={styles.container} data-sentry-component="SelectProvider" data-sentry-source-file="SelectProvider.tsx">
      <GoBackButton onClick={prevStep} data-sentry-element="GoBackButton" data-sentry-source-file="SelectProvider.tsx" />
      <div className={styles.header}>Select your provider</div>
      <div style={{
      margin: '0 16px 16px'
    }}>
        <Input startAdornment={<img src={search} className={styles.icon} alt="" />} label="Search" invalid={false} value={searchInput} onChange={onChange} data-sentry-element="Input" data-sentry-source-file="SelectProvider.tsx" />
      </div>
      <div className={styles.payrolls}>{payrollProvidersToDisplay}</div>
    </div>;
};
export default withAuthorizeContext(SelectProvider);