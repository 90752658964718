import React from 'react';
import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import { CodeInput } from 'components/Input/CodeInput';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
export function SignInBobCredential({
  isLoading,
  onSubmit,
  client,
  handleClick,
  sandboxMode,
  provider
}: RegisterImplementationProps) {
  const instructions = <>
      {sandboxMode === 'provider' ? <p>
          First make sure to{' '}
          <a href="https://developer.tryfinch.com/docs/documentation/pzie4zvmok31z-provider-sandboxes#bob" target="_blank" rel="noreferrer">
            follow the setup instructions
          </a>{' '}
          to get a test account for Bob.
        </p> : null}
      <p>
        <strong>{client.name}</strong> will be added as a service user and
        permission group to retrieve data.
      </p>
    </>;
  return <SignInBase instructions={instructions} onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => {
    return <>
            <MainContainer useNewDesign>
              <Input value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Email Address" id="username" />
              <PasswordInput value={password.value} onChange={event => password.onChange(event.target.value)} reset={{
          uri: new URL('https://app.hibob.com/login/reset-password'),
          providerDisplayName: provider.displayName
        }} invalid={password.invalid} />
            </MainContainer>
            {handleClick && <SwitchImplementationButton onClick={handleClick} useNewDesign>
                Use an API Token instead
              </SwitchImplementationButton>}
            <LoaderButton isLoading={isLoading} disabled={isLoading || !username.value || !password.value} type="submit">
              Connect
            </LoaderButton>
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInBobCredential" data-sentry-source-file="SignInBobCredential.tsx" />;
}