import React from 'react';
import styled from 'styled-components';
import FONTS from 'constants/fonts';
import VersionTag from './VersionTag';
const Block = styled.div`
  height: 24px;
  width: 36px;
  font-size: ${FONTS.P};
  font-weight: 500;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #027a48;
  background-color: #d1fadf;
`;
const BetaTag = () => <VersionTag data-sentry-element="VersionTag" data-sentry-component="BetaTag" data-sentry-source-file="BetaTag.tsx">
    <Block data-sentry-element="Block" data-sentry-source-file="BetaTag.tsx">Beta</Block>
  </VersionTag>;
export default BetaTag;