import React from 'react';
import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import LoaderButton from 'components/Button/LoaderButton';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import { RegisterImplementationProps } from 'constants/types';
import MainContainer from 'components/Container/MainContainer';
const RegisterSaplingApi = ({
  client,
  provider,
  isLoading,
  onSubmit
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions: JSX.Element[] = [<>
      Log in to Sapling and select the integrations icon in the left navigation.
    </>, <>
      Open <strong>API Keys</strong> and click on{' '}
      <strong>Create New Key</strong>.
    </>, <>Name and save your API Key.</>, <>Copy and paste the API key below.</>];
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterSaplingApi.tsx">
        To connect your Sapling account, you must create an API token for{' '}
        <strong>{client.name}</strong>.
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterSaplingApi.tsx" />
      <MultiValueForm onSubmit={onSubmit} fieldNames={['subdomain', 'apiToken']} render={({
      subdomain,
      apiToken
    }) => <>
            <MainContainer>
              <Input id="subdomain" value={subdomain.value} onChange={event => subdomain.onChange(event.target.value)} invalid={subdomain.invalid} label="Subdomain (from https://<subdomain>.sapling.io)" />
              <Input id="apiToken" value={apiToken.value} onChange={event => apiToken.onChange(event.target.value)} invalid={apiToken.invalid} label="API Key" />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit">
              Connect
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterSaplingApi.tsx" />
    </>;
};
export default RegisterSaplingApi;