import React from 'react';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import { InstructionList } from 'components/Instruction/Instructions';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import MainContainer from './SignInMainContainer';
import { RegisterImplementationProps } from 'constants/types';
import NoticeMessage from '../Messages/Notice';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
type SignInApiBaseProps = Pick<RegisterImplementationProps, 'provider' | 'isLoading' | 'onSubmit' | 'handleClick'> & {
  isAccepted: boolean;
  instructions: JSX.Element[];
  header: JSX.Element;
  /** @default "API Token" */
  inputPlaceholder?: string;
  notice?: string;
};
export default function SignInApiBase(props: SignInApiBaseProps): JSX.Element {
  const {
    provider,
    isLoading,
    onSubmit,
    isAccepted,
    instructions,
    inputPlaceholder: inputPlaceholderOverride,
    header,
    notice,
    handleClick
  } = props;
  const inputPlaceholder = inputPlaceholderOverride || 'API Token';
  return <>
      {typeof notice === 'string' && <NoticeMessage>{notice}</NoticeMessage>}
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="SignInApiBase.tsx">{header}</InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="SignInApiBase.tsx" />
      <MultiValueForm onSubmit={onSubmit} fieldNames={['apiToken']} render={({
      apiToken
    }) => <>
            <MainContainer>
              <Input id="apiToken" value={apiToken.value} onChange={event => apiToken.onChange(event.target.value)} invalid={apiToken.invalid} label={inputPlaceholder} />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit">
              Connect
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="SignInApiBase.tsx" />
      {handleClick && <SwitchImplementationButton onClick={handleClick}>
          Use a Username and Password instead
        </SwitchImplementationButton>}
    </>;
}