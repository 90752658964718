import clamp from 'lodash/clamp';
import React, { ChangeEventHandler, KeyboardEventHandler, useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { IntRange } from 'type-fest';
import COLORS from 'constants/colors';

/** The size (width and height) in pixels of each "input" box */
const DIMENSIONS = 46;
const Segment = styled.div<{
  invalid: boolean;
}>`
  box-sizing: border-box;
  border: 1px solid;
  border-radius: 8px;
  border-color: ${props => props.invalid ? COLORS.RED.RED_500 : '#d9d9d9'};
  width: ${DIMENSIONS}px;
  height: ${DIMENSIONS}px;
  max-height: ${DIMENSIONS}px;
  max-width: ${DIMENSIONS}px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #161616;
`;
const Field = styled.div<{
  explode: boolean;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 16px;
  justify-content: ${({
  explode
}) => explode ? 'space-between' : 'space-evenly'};
`;
const Label = styled.div<{
  centered: boolean;
}>`
  font-weight: 500;
  font-size: 14px;
  font-family: Inter;
  color: #161616;
  text-align: ${({
  centered
}) => centered ? 'center' : 'left'};
`;
const RedirectedInput = styled.input<{
  blink: boolean;
}>`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
  height: ${DIMENSIONS}px;
  width: ${DIMENSIONS}px;
  font-size: 14px;
  text-align: center;
  border: 0;
  border-radius: 8px;
  background-color: transparent;
  caret-color: ${({
  blink
}) => blink ? 'initial' : 'transparent'};
`;
const LayerAnchor = styled.div`
  position: relative;
`;
const Wrapper = styled.div`
  padding-bottom: 8px;
`;
type InputRange = IntRange<4, 7>; // the upper bounds is exclusive

export function CodeInput({
  value = '',
  onChange: extOnChange,
  inputs,
  label,
  id,
  invalid
}: {
  value?: string;
  onChange?: (value: string) => void;
  inputs: InputRange;
  id: string;
  label: string;
  invalid: boolean;
}) {
  inputs = clamp(inputs, 4, 6) as InputRange;
  const [code, setCode] = useState<string>(value);
  const [segments] = useState<null[]>(new Array(inputs).fill(null));
  const large = inputs > 5;
  const values = code.split('');
  const inputref = useRef<HTMLInputElement>(null);
  const selectedIndex = values.length < segments.length ? values.length : segments.length - 1;
  const [gap, setGap] = useState<number>(0);
  const wrapperRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setGap((node.getBoundingClientRect().width - DIMENSIONS * inputs) / (inputs < 6 ? inputs + 1 : inputs - 1));
    }
  }, [inputs]);
  const focus = useCallback(() => inputref.current?.focus(), [inputref.current]);
  const onKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(e => {
    switch (e.key) {
      case 'Backspace':
        e.preventDefault();
        if (code.length === 0) {
          return;
        }
        setCode(code.slice(0, code.length - 1));
    }
  }, [code]);
  const onChange = useCallback<ChangeEventHandler<HTMLInputElement>>(e => {
    setCode(prev => {
      let newCode = prev;
      if (prev.length < segments.length) {
        newCode = (prev + e.target.value).slice(0, segments.length);
      }
      if (extOnChange) extOnChange(newCode);
      return newCode;
    });
  }, [extOnChange, segments.length]);
  return <Wrapper ref={wrapperRef} data-sentry-element="Wrapper" data-sentry-component="CodeInput" data-sentry-source-file="CodeInput.tsx">
      <Label centered={!large} data-sentry-element="Label" data-sentry-source-file="CodeInput.tsx">
        {inputs}-Digit {label}
      </Label>
      <LayerAnchor onClick={focus} data-sentry-element="LayerAnchor" data-sentry-source-file="CodeInput.tsx">
        <Field explode={large} data-sentry-element="Field" data-sentry-source-file="CodeInput.tsx">
          {segments.map((_, index) => <Segment key={index} invalid={invalid}>
              {values[index] || ''}
            </Segment>)}
        </Field>
        <RedirectedInput value="" // NOTE: the input value is displayed above as components
      maxLength={inputs} ref={inputref} id={id} blink={code.length !== inputs} style={{
        left: selectedIndex * (DIMENSIONS + gap),
        marginLeft: large ? 0 : gap,
        outlineOffset: '-1px',
        outlineColor: COLORS.FINCH.PURPLE
      }} onKeyDown={onKeyDown} onChange={onChange} data-sentry-element="RedirectedInput" data-sentry-source-file="CodeInput.tsx" />
      </LayerAnchor>
    </Wrapper>;
}