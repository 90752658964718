import { useEffect, useState } from 'react';
import _, { isEqual } from 'lodash';
import { authorize } from 'services/auth';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import Container from 'components/Container/Container';
import { PayrollProviderId } from 'constants/types';
import { getStatusCode, validateSubdomain } from './utils';
import LoadingSpinner from '../LoadingSpinner';
import { getProviderComponent, useNewProviderComponent } from './providerToComponentMap';
import RegisterProviderContainer from './RegisterProviderContainer';
import useImplementations from './useImplementations';
import NoticeMessage from '../Messages/Notice';
import { useStagedConfigStore } from '../useStagedConfig';
import { Clients } from '../../../constants/clients';
import BetaTag from '../../../components/VersionTag/BetaTag';
import { isImplementationInBeta } from '../../../utils/providers';
import { GoBackButton } from 'components/Button/GoBackButton';
import { isAssistedImplementationKind } from '@finch-api/common/dist/internal/connect/authorize';
import { AccountStatus } from '@finch-api/common/dist/external/dashboard/connection-status';
import ManualSignIn from '../ManualSignIn/ManualSignIn';
import { useAuthFailureCount } from '../AuthFallbackBoundary';
import { useFlag } from '@unleash/proxy-client-react';
import { EMPLOYER_PREFIX } from 'constants/products';
export type SubmitOptions = {
  subdomain?: string;
  fullDomain?: string;
  username?: string;
  password?: string;
  apiToken?: string;
  companyId?: string;
  providerClientId?: string;
  providerClientSecret?: string;
  providerRedirectUri?: string;
  clientCode?: string;
  SID?: string;
  step?: string;
  reportUrl?: string;
  tenantId?: string;
  apiBaseUrl?: string;
  codeVerifier?: string;
  codeChallenge?: string;
  codeChallengeMethod?: string;
  idpRedirect?: boolean;
};
const SignIn = ({
  clientId,
  connectionId,
  connection,
  clientName,
  redirectUri,
  products,
  sessionKey,
  currentBrowserSessionKey,
  state,
  category,
  providersConfig,
  payrollProvider,
  handleAuthorizeResponse,
  handleStateRedirect,
  sandbox,
  client,
  prevStep,
  sdkVersion,
  appType,
  hasBenefitsProduct,
  implementationHasAssistedBenefits,
  setProviderImplementation,
  setUsingAcfFallback,
  currentStep,
  setProviderToRedirect,
  setError,
  idpRedirect
}: AuthorizeContextValue) => {
  const [loading, setLoading] = useState<boolean>(false);
  const useNewDesign = useFlag('finchConnectCleanup');
  const {
    authFailureCounts,
    setAuthFailureCounts
  } = useAuthFailureCount();
  if (!payrollProvider) throw new Error('no payroll provider');
  const stagedConfig = useStagedConfigStore(state => state.stagedConfig);
  const {
    implementationKind,
    onSwitchImplementationKind,
    implementationDetail
  } = useImplementations({
    provider: payrollProvider,
    setError,
    idpRedirect
  });
  const newContainerDesign = useNewProviderComponent(payrollProvider.id as PayrollProviderId, implementationKind, useNewDesign);
  useEffect(() => {
    setProviderImplementation(implementationDetail);
  }, [implementationDetail, setProviderImplementation]);
  if (!client) return <Container>{!client && <LoadingSpinner />}</Container>;
  if (!providersConfig) throw new Error('no provider configs');
  if (!products) throw new Error('no products');

  // it should either return api or credential implementation detail
  if (!implementationDetail) throw new Error(`${payrollProvider.id} ${implementationKind} kind does not have an implementation detail`);
  if (isAssistedImplementationKind(implementationKind)) {
    return <ManualSignIn />;
  }

  // if the connection is connected, the products haven't changed, and the assisted status is not connected,
  // then we need to show just the manual sign in screen
  if (connection && connection.automatedStatus === AccountStatus.CONNECTED &&
  // We will only skip the automated screen if the existing connection
  // scopes match match the requested products
  isEqual([...connection.automatedScopes, ...connection.assistedScopes].map(scope => scope.replace(EMPLOYER_PREFIX, '')), products) && connection.assistedStatus && connection.assistedStatus !== AccountStatus.CONNECTED) {
    return <ManualSignIn />;
  }
  const ImplementationComponent = getProviderComponent(payrollProvider.id as PayrollProviderId, implementationKind, useNewDesign);
  if (!ImplementationComponent) return <Container>{`Automated flows do not support the ${implementationKind} authentication method.`}</Container>;
  const handleSubmit = async (fieldToValueMap: SubmitOptions) => {
    const {
      subdomain,
      fullDomain,
      username,
      password,
      apiToken,
      companyId,
      providerClientId,
      providerClientSecret,
      providerRedirectUri,
      clientCode,
      SID,
      step,
      reportUrl,
      tenantId,
      apiBaseUrl,
      codeVerifier,
      codeChallenge,
      codeChallengeMethod
    } = fieldToValueMap;
    setLoading(true);
    setError(null);
    if (stagedConfig) {
      setError({
        message: 'You cannot complete a Connect session while in preview mode.',
        omitSessionKey: true
      });
      setLoading(false);
      return;
    }
    if (subdomain) {
      if (!validateSubdomain(subdomain)) {
        setError({
          message: 'Invalid subdomain. Subdomain should contain no spaces, symbols, or http prefixes',
          omitSessionKey: true
        });
        setLoading(false);
        return;
      }
      setError(null);
    }
    try {
      const authorizeResponse = await authorize({
        clientId,
        connectionId,
        clientName,
        redirectUri,
        products,
        username,
        password,
        providerClientId,
        providerClientSecret,
        providerRedirectUri,
        companyId,
        clientCode,
        domain: subdomain,
        fullDomain,
        reportUrl,
        tenantId,
        apiBaseUrl,
        apiToken,
        SID,
        sessionKey,
        currentBrowserSessionKey,
        state,
        category,
        payrollProviderId: payrollProvider.id,
        providerImplementationId: implementationDetail.id,
        sandbox,
        sdkVersion,
        step,
        appType,
        codeChallenge,
        codeChallengeMethod,
        codeVerifier,
        implementationHasAssistedBenefits
      });
      handleAuthorizeResponse(authorizeResponse);
    } catch (err: any) {
      if (_.get(err, 'response.data.finch_code') === 'incorrect_payroll_provider') {
        const correctPayrollProvider = _.get(err, 'response.data.correct_payroll_provider');
        setProviderToRedirect(correctPayrollProvider);
      }
      setError({
        message: err.message || 'An unexpected error has occurred. Please try again.'
      });
      const errStatusCode = getStatusCode(err);
      if (500 <= errStatusCode && errStatusCode < 600) {
        const failures = authFailureCounts[implementationDetail.id] ?? 0;
        setAuthFailureCounts({
          ...authFailureCounts,
          [implementationDetail.id]: failures + 1
        });
      }
    }
    setLoading(false);
  };
  const handleMockOAuth = async () => {
    const isFinchSandbox = sandbox === 'finch';
    const isDemoApp = clientId === Clients.Finch.Homepage;
    if (!isFinchSandbox && !isDemoApp) {
      return false;
    }
    if (isFinchSandbox) {
      await handleSubmit({
        step: 'NEW_SANDBOX_OAUTH_CONNECTION'
      });
      return true;
    }
    if (isDemoApp) {
      await handleSubmit({});
      return true;
    }
    return false;
  };
  return <>
      {providersConfig.length > 1 && <GoBackButton onClick={() => prevStep()} />}
      {isImplementationInBeta(implementationDetail) && <BetaTag />}
      <RegisterProviderContainer client={client} provider={payrollProvider} products={products} setError={setError} hasBenefitsProduct={hasBenefitsProduct} currentStep={currentStep} implementationDetail={implementationDetail} authFailureCounts={authFailureCounts} setUsingAcfFallback={setUsingAcfFallback} handleStateRedirect={handleStateRedirect} sandboxMode={sandbox} newContainerDesign={newContainerDesign} connection={connection} data-sentry-element="RegisterProviderContainer" data-sentry-source-file="SignIn.tsx">
        {sandbox === 'provider' && hasBenefitsProduct && implementationHasAssistedBenefits && <NoticeMessage>
              The benefits product is being requested, but assisted benefits is
              not supported in Sandbox mode. Any generated tokens for this
              provider will not have benefits scopes.
            </NoticeMessage>}
        <ImplementationComponent client={client} provider={payrollProvider} implementationDetail={implementationDetail} isLoading={loading} onSubmit={handleSubmit} handleClick={onSwitchImplementationKind} products={products} sandboxMode={sandbox} onMockOAuth={handleMockOAuth} data-sentry-element="ImplementationComponent" data-sentry-source-file="SignIn.tsx" />
      </RegisterProviderContainer>
    </>;
};
export default withAuthorizeContext(SignIn);