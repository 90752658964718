import GenericError from 'pages/Authorize/Errors/GenericError';
import React from 'react';
import { Code } from './styles';
const InvalidSandboxApplication = ({
  shortSessionKey
}: {
  shortSessionKey?: string;
}) => <GenericError buttonLabel="Try Again" errorCode={shortSessionKey} title="Invalid Client" data-sentry-element="GenericError" data-sentry-component="InvalidSandboxApplication" data-sentry-source-file="InvalidSandboxApplication.tsx">
    Please use the <Code data-sentry-element="Code" data-sentry-source-file="InvalidSandboxApplication.tsx">client_id</Code> of your sandbox application to create
    sandbox connections.
  </GenericError>;
export default InvalidSandboxApplication;