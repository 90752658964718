import React from 'react';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import { RegisterImplementationProps } from 'constants/types';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
import InstructionHeader from '../../../../components/Instruction/InstructionHeader';
import SignInCredentialBase from '../SignInCredentialBase';
const RegisterSquarePayrollCredential = ({
  provider,
  isLoading,
  onSubmit,
  handleClick,
  sandboxMode
}: RegisterImplementationProps) => <>
    {sandboxMode === 'provider' && <InstructionHeader>
        Sign up for a free Square account{' '}
        <a href="https://squareup.com/signup?country_code=us&v=payroll" target="_blank" rel="noreferrer">
          on their website
        </a>{' '}
        and connect it here.
      </InstructionHeader>}
    <MultiValueForm onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => <SignInCredentialBase username={username} password={password} pwreset={new URL('https://app.squareup.com/password')} provider={provider} isLoading={isLoading} disclaimer="A third-party Admin/Accountant will be added." />} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterSquarePayrollCredential.tsx" />
    {handleClick && <SwitchImplementationButton onClick={handleClick}>
        Authenticate through Square
      </SwitchImplementationButton>}
  </>;
export default RegisterSquarePayrollCredential;