import { create } from 'zustand';
import React, { useEffect } from 'react';
import { ImplementationDetail, ProviderConfig, Response as ClientResponse } from '@finch-api/common/dist/internal/connect/validate';
import { shouldRedirectToAuthFallback } from 'lib/auth-fallback/utils';
import { step } from './steps';
import { StateRedirect, ConnectError } from './types';
export const useAuthFailureCount = create<{
  authFailureCounts: Record<string, number>;
  setAuthFailureCounts: (authFailureCounts: Record<string, number>) => void;
}>(set => ({
  authFailureCounts: {},
  setAuthFailureCounts: (authFailureCounts: Record<string, number>) => set({
    authFailureCounts
  })
}));
const AuthFallbackBoundary = ({
  client,
  provider,
  implementationDetail,
  setUsingAcfFallback,
  handleStateRedirect,
  children,
  setError
}: {
  client: ClientResponse;
  provider: ProviderConfig | undefined;
  implementationDetail: ImplementationDetail | undefined;
  setUsingAcfFallback: (value: React.SetStateAction<boolean>) => void;
  handleStateRedirect: (redirect: StateRedirect) => void;
  children: React.ReactNode;
  setError: (error: ConnectError | null) => void;
}): JSX.Element | null => {
  const authFailureCounts = useAuthFailureCount(state => state.authFailureCounts);
  useEffect(() => {
    const usingFallback = shouldRedirectToAuthFallback(client, authFailureCounts, implementationDetail);
    setUsingAcfFallback(usingFallback);
    if (usingFallback && provider) {
      setError(null);
      handleStateRedirect({
        next: step.MANUAL_SIGN_IN,
        nextContext: {
          redirect: true,
          payrollProviderId: provider.id
        }
      });
    }
  }, [authFailureCounts, client, handleStateRedirect, implementationDetail, provider, setUsingAcfFallback, setError]);
  if (!provider) return null;
  return <>{children}</>;
};
export default AuthFallbackBoundary;