import styled from 'styled-components';
const Container = styled.div<{
  newContainerDesign?: boolean;
}>`
  height: 100%;
  position: relative;
  padding: ${({
  newContainerDesign
}) => newContainerDesign ? '56px 24px 24px' : '42px 24px'};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
`;
export default Container;