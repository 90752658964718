import React, { useRef, useState } from 'react';
import { v1 as uuidv1 } from 'uuid';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import MainContainer from 'components/Container/MainContainer';
import NoticeMessage from '../../Messages/Notice';
const {
  protocol,
  host
} = window.location;
const OYSTER_HR_REDIRECT_URI = `${protocol}//${host}/auth/oyster_hr`;
const RegisterOysterHrOAuth = ({
  client,
  onSubmit,
  provider,
  setError,
  onMockOAuth
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const [loading, setLoading] = useState(false);
  const externalWindowRef = useRef<Window | null>(null);
  const knownState = `oyster_hr-${uuidv1()}`;

  // use any for type until Finch common types can be updated
  const config = (client as any)?.oysterHrApiConfig;
  const {
    authUrl
  } = config;
  if (!config?.authUrl) {
    throw new Error('OysterHR API config is missing authUrl');
  }
  const closeWindow = () => {
    setLoading(false);
    externalWindowRef?.current?.close();
  };

  // Function to run when user initiates Oyster OAuth
  const handleSubmit = async () => {
    setLoading(true);
    const didMockOAuth = await onMockOAuth();
    if (didMockOAuth) {
      setLoading(false);
      return;
    }

    // Setup the request to initiate Oyster OAuth
    const url = new URL(authUrl);
    url.searchParams.set('state', knownState);
    externalWindowRef.current = window.open(url.toString(), 'popup', 'popup=true');
    if (!externalWindowRef.current || externalWindowRef.current.closed) return;
    const checkWindowInterval = setInterval(async () => {
      if (externalWindowRef?.current?.closed) {
        clearInterval(checkWindowInterval);
      } else {
        try {
          const urlParams = new URLSearchParams(externalWindowRef?.current?.location.search);
          const receivedState = urlParams.get('state');
          if (receivedState !== knownState) return;
          const code = urlParams.get('code');
          const error = urlParams.get('error');
          if (code) {
            closeWindow();
            await onSubmit({
              clientCode: code,
              providerRedirectUri: OYSTER_HR_REDIRECT_URI
            });
          }
          if (error) {
            setError({
              message: error
            });
            closeWindow();
          }
        } catch (e) {
          // ignore dom exception errors
          if (e instanceof DOMException) {
            return;
          }
          setError({
            message: JSON.stringify(e)
          });
          closeWindow();
        }
      }
    }, 500);
  };
  return <>
      <NoticeMessage data-sentry-element="NoticeMessage" data-sentry-source-file="RegisterOysterHrOAuth.tsx">
        <div>
          OysterHR authentication will continue in a new window. Please make
          sure sure sure to allow popups and finish authenticating through
          OysterHR in window.
        </div>
      </NoticeMessage>
      <MainContainer data-sentry-element="MainContainer" data-sentry-source-file="RegisterOysterHrOAuth.tsx" />
      <LoaderButton isLoading={loading} disabled={loading} onClick={handleSubmit} data-sentry-element="LoaderButton" data-sentry-source-file="RegisterOysterHrOAuth.tsx">
        Connect
      </LoaderButton>
    </>;
};
export default withAuthorizeContext(RegisterOysterHrOAuth);