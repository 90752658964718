import React from 'react';
import { toWords } from 'number-to-words';
import { FINCH_SANDBOX_CONNECTION_LIMIT } from '@finch-api/common/dist/internal/sandbox';
import GenericError from 'pages/Authorize/Errors/GenericError';
const FinchSandboxConnectionLimit = ({
  shortSessionKey
}: {
  shortSessionKey?: string;
}) => <GenericError buttonLabel="Reload" errorCode={shortSessionKey} title="Sandbox Connection Limit" data-sentry-element="GenericError" data-sentry-component="FinchSandboxConnectionLimit" data-sentry-source-file="FinchSandboxConnectionLimit.tsx">
    {`The Finch Sandbox is limited to ${toWords(FINCH_SANDBOX_CONNECTION_LIMIT)} connections. Please disconnect an
    existing connection to add a new one.`}
  </GenericError>;
export default FinchSandboxConnectionLimit;