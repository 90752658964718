import React from 'react';
import GenericError from 'pages/Authorize/Errors/GenericError';
import { Sandbox } from '@finch-api/common/dist/internal/connect/authorize';
import { capitalize } from 'lodash';
const urlWithoutAssisted = () => {
  const url = new URL(window.location.href);
  url.searchParams.delete('manual');
  return url.toString();
};
export const CanNotUseAssistedInSandbox = ({
  mode
}: {
  mode: Sandbox;
}) => <GenericError title="Can not use Assisted Connect" data-sentry-element="GenericError" data-sentry-component="CanNotUseAssistedInSandbox" data-sentry-source-file="CanNotUseAssisted.tsx">
    Assisted Connect is not supported in{' '}
    {capitalize(typeof mode === 'boolean' ? 'finch' : mode)} Sandbox mode.{' '}
    <a href={urlWithoutAssisted()}>Click here</a> to switch out of Assisted
    Connect.
  </GenericError>;