import React, { useState } from 'react';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import { Pkce, useOAuthStatePolling } from '../../OAuth/useOAuthStatePolling';
import SignInBase from '../SignInBase';
const PAYCOR_REDIRECT_URI = `${window.location.protocol}//${window.location.host}/auth/paycor`;
interface PaycorConfig {
  oauthUrl: string;
  clientId: string;
  scopes: string[];
  apimSubscriptionKey: string;
  signInUrl: string;
}
const RegisterPaycorOAuth = ({
  client,
  onSubmit,
  sandbox,
  setError,
  sessionKey,
  onMockOAuth
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const onCodeReceived = async (clientCode: string, pkce: Pkce) => {
    await onSubmit({
      codeVerifier: pkce.code_verifier,
      codeChallenge: pkce.code_challenge,
      codeChallengeMethod: 's256',
      providerRedirectUri: PAYCOR_REDIRECT_URI,
      clientCode
    });
  };
  const {
    loading,
    openOAuthWindow,
    pkce
  } = useOAuthStatePolling({
    sessionKey,
    onCodeReceived,
    setError
  });
  const prodConfig: PaycorConfig = (client as any).paycorApiConfig.production;
  const sandboxConfig: PaycorConfig = (client as any).paycorApiConfig.sandbox;
  const config = sandbox ? sandboxConfig : prodConfig;
  const getOAuthUrl = () => {
    // Set up the request to initiate Paycor OAuth.
    const url = new URL(config.oauthUrl);
    url.searchParams.set('client_id', config.clientId);
    url.searchParams.set('scope', config.scopes.join(' '));
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('redirect_uri', PAYCOR_REDIRECT_URI);
    url.searchParams.set('subscription-key', config.apimSubscriptionKey);
    url.searchParams.set('code_challenge', pkce.code_challenge);
    url.searchParams.set('code_challenge_method', 's256');
    url.searchParams.set('state', JSON.stringify({
      sessionKey
    }));
    const redirectUrl = new URL(config.signInUrl);
    redirectUrl.searchParams.set('ReturnURL', url.toString());
    return redirectUrl.toString();
  };
  const onOAuthClick = async () => {
    setButtonLoading(true);
    const didMockOAuth = await onMockOAuth();
    if (didMockOAuth) {
      setButtonLoading(false);
      return;
    }
    openOAuthWindow(getOAuthUrl());
    setButtonLoading(false);
  };
  const instructions = <p>
      Paycor authentication will continue in a new window. Please make sure to
      allow popups and finish authenticating through Paycor in the new window.
    </p>;
  return <SignInBase instructions={instructions} data-sentry-element="SignInBase" data-sentry-component="RegisterPaycorOAuth" data-sentry-source-file="SignInPaycorOAuth.tsx">
      <div style={{
      marginTop: 'auto',
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
        <LoaderButton isLoading={loading || buttonLoading} disabled={loading || buttonLoading} onClick={onOAuthClick} data-sentry-element="LoaderButton" data-sentry-source-file="SignInPaycorOAuth.tsx">
          Connect
        </LoaderButton>
      </div>
    </SignInBase>;
};
export default withAuthorizeContext(RegisterPaycorOAuth);