import React from 'react';
import { Response as ClientResponse, ProviderConfig } from '@finch-api/common/dist/internal/connect/validate';
import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import payrollProviderToLocalLogosMap from 'constants/payrollProviderToLocalLogosMap';
import styled from 'styled-components';
import Container from 'components/Container/Container';
import Header from 'components/Header/Header';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import LoaderButton from 'components/Button/LoaderButton';
import ProviderLogo from 'components/Logo/ProviderLogo';
import { instructionsForPayrollProviderId } from './instructions';
import { StateRedirect } from '../types';
import { GoBackButton } from 'components/Button/GoBackButton';
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ImplementationWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;
export const FINCH_DEFAULT_PHONE = '(407) 741-3626';
const ManualInstruction = ({
  client,
  payrollProvider,
  contactEmailInContext,
  companyNameInContext,
  onSubmit,
  onPrevStep,
  handleStateRedirect
}: {
  client: ClientResponse;
  payrollProvider: ProviderConfig;
  contactEmailInContext: string | undefined;
  companyNameInContext: string | undefined;
  onSubmit: () => Promise<void>;
  onPrevStep: () => void;
  handleStateRedirect: (redirect: StateRedirect) => void;
}) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions = instructionsForPayrollProviderId(payrollProvider.id)({
    accountantEmail: client?.manualConfig?.email || payrollProvider?.defaultAccountantEmail,
    username: client?.manualConfig?.name || client?.name,
    userId: client?.manualConfig?.userId || client?.manualConfig?.name || client?.name,
    ssn: client?.manualConfig?.ssn,
    phoneNumber: client?.manualConfig?.phoneNumber || FINCH_DEFAULT_PHONE
  });
  const isXeroUSProvider = payrollProvider.id === 'xero_us';
  const onRedirect = () => {
    handleStateRedirect({
      next: 'SIGN_IN',
      nextContext: {
        redirect: true,
        payrollProviderId: 'gusto'
      }
    });
  };
  return <>
      {!(companyNameInContext && contactEmailInContext) && <GoBackButton onClick={onPrevStep} />}
      <Container data-sentry-element="Container" data-sentry-source-file="ManualInstruction.tsx">
        <HeaderWrapper data-sentry-element="HeaderWrapper" data-sentry-source-file="ManualInstruction.tsx">
          <ProviderLogo src={payrollProviderToLocalLogosMap[payrollProvider.id].largeLogo} alt="" data-sentry-element="ProviderLogo" data-sentry-source-file="ManualInstruction.tsx" />
          <Header data-sentry-element="Header" data-sentry-source-file="ManualInstruction.tsx">
            Connect your
            {` ${payrollProvider.displayName} `}
            account
          </Header>
        </HeaderWrapper>
        <ImplementationWrapper data-sentry-element="ImplementationWrapper" data-sentry-source-file="ManualInstruction.tsx">
          <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="ManualInstruction.tsx">
            To connect your {payrollProvider.displayName} account, you must add{' '}
            <b>{client.name}</b> as an admin to your payroll system. The steps
            are below.
          </InstructionHeader>
          <InstructionList instructions={instructions} color={payrollProvider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="ManualInstruction.tsx" />
          <LoaderButton color={payrollProvider.primaryColor} onClick={isXeroUSProvider ? onRedirect : onSubmit} disabled={!isAccepted} data-sentry-element="LoaderButton" data-sentry-source-file="ManualInstruction.tsx">
            {isXeroUSProvider ? 'Redirect' : 'Complete'}
          </LoaderButton>
        </ImplementationWrapper>
      </Container>
    </>;
};
export default ManualInstruction;