import {
  ImplementationDetail,
  Response as ClientResponse,
} from '@finch-api/common/dist/internal/connect/validate';

/**
 * Check with respect to client & provider implementation whether or not
 * we should redirect to auth fallback depending on:
 *
 *  - the implementation config
 *  - the client's feature flags
 *  - the count of auth failures hit with that implementation
 *
 * TODO: remove clientID when ACF fallback is fully deployed.
 */
// eslint-disable-next-line import/prefer-default-export
export const shouldRedirectToAuthFallback = (
  client: ClientResponse,
  authFailures: Record<string, number>,
  impl?: ImplementationDetail,
) => {
  if (!impl) return false;
  const {
    id,
    acfFallbackEligible,
    attemptsBeforeFallback,
    authFallbackEnabled,
  } = impl;

  if (!acfFallbackEligible || !authFallbackEnabled) return false;

  if (client.settings.authFallbackEligible && attemptsBeforeFallback === 0)
    return true;

  if (client.settings.authFallbackEligible)
    return authFailures[id] >= attemptsBeforeFallback;

  return false;
};
