import React from 'react';
import { startCase } from 'lodash';
import { useLocation } from 'react-router-dom';
import payrollProviderToLocalLogosMap from '../../../constants/payrollProviderToLocalLogosMap';
import { RedirectPage } from '../../../components/RedirectPage';
export const OAuthRedirect = ({
  isLoading = false,
  error
}: {
  isLoading?: boolean;
  error?: unknown;
}) => {
  const {
    pathname
  } = useLocation();
  const paths = pathname.split('/');
  const providerId = paths[paths.length - 1];
  const displayName = startCase(providerId);
  return <RedirectPage errorText={{
    header: 'Something went wrong!',
    message: <>
            Please close this window and retry the authentication process. If
            the issue persists please contact developers@tryfinch.com
          </>
  }} successText={{
    header: 'Success!',
    message: <>
            You have successfully authenticated
            {displayName && ` through ${displayName}`}
            {'. '}
            This pop-up window will automatically close soon
          </>
  }} error={error} isLoading={isLoading} key={providerId} logoSrc={payrollProviderToLocalLogosMap[providerId || 'finch'].largeLogo} data-sentry-element="RedirectPage" data-sentry-component="OAuthRedirect" data-sentry-source-file="OAuthRedirect.tsx" />;
};
OAuthRedirect.defaultProps = {
  isLoading: false,
  error: null
};