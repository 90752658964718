import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { create } from 'zustand';
import { useInView } from 'react-intersection-observer';
import COLORS from 'constants/colors';
import Checkbox from 'components/Input/CheckboxInput';
import InstructionContainer from './InstructionContainer';
import InstructionItem from './InstructionItem';
const InstructionWrapper = styled.div`
  flex-grow: 1;
  padding: 16px;
  overflow-y: auto;

  max-height: 200px;
  overflow: auto;

  background:
    linear-gradient(white 30%, rgba(255, 255, 255, 0)) center top,
    linear-gradient(rgba(255, 255, 255, 0), white 70%) center bottom,
    radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center top,
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center bottom;

  background-repeat: no-repeat;
  background-size:
    100% 20px,
    100% 20px,
    100% 8px,
    100% 8px;
  background-attachment: local, local, scroll, scroll;

  /* width and height of the scrollbar track */
  ::-webkit-scrollbar {
    width: 4px;
    height: 10px;
  }

  /* style of the scrollbar thumb */
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.GRAY.GRAY_400};
    border-radius: 2px;
  }

  /* style of the scrollbar track */
  ::-webkit-scrollbar-track {
    background: ${COLORS.WHITE};
    border-radius: 2px;
  }
`;
const CheckboxWrapper = styled.div`
  border-top: 1px solid ${COLORS.GRAY.GRAY_400};
  padding: 8px 16px;
  position: sticky;
  bottom: 0;
`;
export const useInstructions = create<{
  isAccepted: boolean;
  setIsAccepted: (isAccepted: boolean) => void;
}>(set => ({
  isAccepted: false,
  setIsAccepted: (isAccepted: boolean) => set(() => ({
    isAccepted
  }))
}));
export const InstructionList = ({
  instructions,
  color
}: {
  instructions: JSX.Element[];
  color: string;
}) => {
  const {
    isAccepted,
    setIsAccepted
  } = useInstructions();
  const {
    ref,
    inView
  } = useInView({
    threshold: 0
  });
  const onClickCheckbox = (checked: boolean) => {
    setIsAccepted(checked);
  };
  const [isDoneReadingInstructions, setIsDoneReadingInstructions] = useState(false);
  const checkboxLabel = isDoneReadingInstructions ? "I've read and completed the instructions." : 'Finish reading the instructions to continue.';
  useEffect(() => {
    if (inView) {
      setIsDoneReadingInstructions(true);
    }
  }, [inView]);
  return <InstructionContainer data-sentry-element="InstructionContainer" data-sentry-component="InstructionList" data-sentry-source-file="Instructions.tsx">
      <InstructionWrapper data-sentry-element="InstructionWrapper" data-sentry-source-file="Instructions.tsx">
        {instructions.map((instruction, i) => <InstructionItem key={`step-${i + 1}`} index={i + 1} badgeColor={color}>
            {instruction}
          </InstructionItem>)}
        {/* Bottom of instructions anchor */}
        <div ref={ref} />
      </InstructionWrapper>
      <CheckboxWrapper data-sentry-element="CheckboxWrapper" data-sentry-source-file="Instructions.tsx">
        <Checkbox id="instruction-checkbox" label={checkboxLabel} checked={isAccepted} color={color} disabled={!isDoneReadingInstructions} onChange={onClickCheckbox} data-sentry-element="Checkbox" data-sentry-source-file="Instructions.tsx" />
      </CheckboxWrapper>
    </InstructionContainer>;
};