import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import Button from 'components/Button/Button';
import Loading from 'components/Loading/Loading';
import COLORS from 'constants/colors';
interface LoaderButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  color?: string;
  isLoading?: boolean;
  disabled?: boolean;
}
const LoaderButton = ({
  children,
  color = COLORS.GRAY.GRAY_900,
  isLoading = false,
  disabled = false,
  onClick,
  ...props
}: LoaderButtonProps) => <Button color={color} isLoading={isLoading} disabled={disabled} onClick={onClick} {...props} data-sentry-element="Button" data-sentry-component="LoaderButton" data-sentry-source-file="LoaderButton.tsx">
    {children}
    <Loading color={color} isLoading={isLoading} disabled={disabled} data-sentry-element="Loading" data-sentry-source-file="LoaderButton.tsx" />
  </Button>;
LoaderButton.defaultProps = {
  isLoading: false,
  disabled: false
};
export default LoaderButton;