import React from 'react';
import { RegisterImplementationProps } from 'constants/types';
import SignInBase from '../SignInBase';
import MainContainer from '../SignInMainContainer';
import LoaderButton from 'components/Button/LoaderButton';
import Input from 'components/Input/Input';
import PasswordInput from 'components/Input/PasswordInput';
import { SwitchImplementationButton } from 'components/LinkButton/LinkButton';
const SignInInsperityCredential = ({
  isLoading,
  onSubmit,
  provider,
  handleClick
}: RegisterImplementationProps) => {
  return <SignInBase onSubmit={onSubmit} fieldNames={['username', 'password']} render={({
    username,
    password
  }) => {
    return <>
            <MainContainer useNewDesign>
              <Input value={username.value} onChange={event => username.onChange(event.target.value)} invalid={username.invalid} label="Username" id="username" />
              <PasswordInput reset={{
          uri: new URL('https://passportapps.insperity.com/SSO/ForgotPassword'),
          providerDisplayName: provider.displayName
        }} value={password.value} onChange={event => password.onChange(event.target.value)} invalid={password.invalid} />
            </MainContainer>
            {handleClick && <SwitchImplementationButton onClick={handleClick} useNewDesign>
                Use an API Token instead
              </SwitchImplementationButton>}
            <LoaderButton isLoading={isLoading} disabled={isLoading || !username.value || !password.value} type="submit">
              Connect
            </LoaderButton>
          </>;
  }} data-sentry-element="SignInBase" data-sentry-component="SignInInsperityCredential" data-sentry-source-file="SignInInsperityCredential.tsx" />;
};
export default SignInInsperityCredential;