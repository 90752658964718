import { FINCH_BASE_URL } from 'constants/urls';
import React, { useEffect } from 'react';

/**
 * Redirects the user to the base URL after a short delay.
 * The delay exists to allow OAuth implementations to have time to read the URL parameters.
 */
const RedirectBase = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.href = FINCH_BASE_URL;
    }, 500);
    return () => clearTimeout(timeout);
  }, []);
  return <></>;
};
export default RedirectBase;