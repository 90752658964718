import React from 'react';
import { InstructionList, useInstructions } from 'components/Instruction/Instructions';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import MultiValueForm from 'components/MultiValueForm/MultiValueForm';
import Input from 'components/Input/Input';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import MainContainer from 'components/Container/MainContainer';
const RegisterAlphaStaffApi = ({
  provider,
  isLoading,
  onSubmit
}: RegisterImplementationProps) => {
  const {
    isAccepted
  } = useInstructions();
  const instructions: JSX.Element[] = [<>
      Enter your 6 digit AlphaStaff client ID. If you do not know this
      please&nbsp;
      <a href="https://www.alphastaff.com/contact/" target="_blank" rel="noreferrer">
        contact your AlphaStaff representative.
      </a>
    </>, <>
      Your account&apos;s connection will not be complete until you respond to
      the access confirmation request from AlphaStaff. You should receive this
      within 7 business days of submitting this form.
    </>];
  return <>
      <InstructionHeader data-sentry-element="InstructionHeader" data-sentry-source-file="RegisterAlphaStaffApi.tsx">
        To connect your AlphaStaff account you must enter your 6 digit client ID
      </InstructionHeader>
      <InstructionList instructions={instructions} color={provider.primaryColor} data-sentry-element="InstructionList" data-sentry-source-file="RegisterAlphaStaffApi.tsx" />
      <MultiValueForm onSubmit={onSubmit} fieldNames={['companyId']} render={({
      companyId
    }) => <>
            <MainContainer>
              <Input id="companyId" value={companyId.value} onChange={event => companyId.onChange(event.target.value)} invalid={companyId.invalid} label="Client ID" autoFocus />
            </MainContainer>
            <LoaderButton isLoading={isLoading} disabled={isLoading || !isAccepted} type="submit">
              Connect
            </LoaderButton>
          </>} data-sentry-element="MultiValueForm" data-sentry-source-file="RegisterAlphaStaffApi.tsx" />
    </>;
};
export default RegisterAlphaStaffApi;