import React, { useState } from 'react';
import LoaderButton from 'components/Button/LoaderButton';
import { RegisterImplementationProps } from 'constants/types';
import { AuthorizeContextValue, withAuthorizeContext } from 'pages/Authorize/AuthorizeContext';
import InstructionHeader from 'components/Instruction/InstructionHeader';
import { Pkce, useOAuthStatePolling } from '../../OAuth/useOAuthStatePolling';
import FONTS from '../../../../constants/fonts';
import COLORS from '../../../../constants/colors';
import styled from 'styled-components';
const PAYCOR_REDIRECT_URI = `${window.location.protocol}//${window.location.host}/auth/paycor`;
const StatusMessage = styled.p`
  margin-top: 8px;
  font-size: ${FONTS.P};
  color: ${COLORS.GRAY.GRAY_600};
  text-align: center;
`;
interface PaycorConfig {
  oauthUrl: string;
  clientId: string;
  scopes: string[];
  apimSubscriptionKey: string;
  signInUrl: string;
}
const RegisterPaycorOAuth = ({
  client,
  provider,
  onSubmit,
  sandbox,
  setError,
  sessionKey,
  onMockOAuth
}: RegisterImplementationProps & AuthorizeContextValue) => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const onCodeReceived = async (clientCode: string, pkce: Pkce) => {
    await onSubmit({
      codeVerifier: pkce.code_verifier,
      codeChallenge: pkce.code_challenge,
      codeChallengeMethod: 's256',
      providerRedirectUri: PAYCOR_REDIRECT_URI,
      clientCode
    });
  };
  const {
    loading,
    openOAuthWindow,
    pkce,
    statusMessage
  } = useOAuthStatePolling({
    sessionKey,
    onCodeReceived,
    setError
  });
  const prodConfig: PaycorConfig = (client as any).paycorApiConfig.production;
  const sandboxConfig: PaycorConfig = (client as any).paycorApiConfig.sandbox;
  const config = sandbox ? sandboxConfig : prodConfig;
  const getOAuthUrl = () => {
    // Set up the request to initiate Paycor OAuth.
    const url = new URL(config.oauthUrl);
    url.searchParams.set('client_id', config.clientId);
    url.searchParams.set('scope', config.scopes.join(' '));
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('redirect_uri', PAYCOR_REDIRECT_URI);
    url.searchParams.set('subscription-key', config.apimSubscriptionKey);
    url.searchParams.set('code_challenge', pkce.code_challenge);
    url.searchParams.set('code_challenge_method', 's256');
    url.searchParams.set('state', JSON.stringify({
      sessionKey
    }));
    const redirectUrl = new URL(config.signInUrl);
    redirectUrl.searchParams.set('ReturnURL', url.toString());
    return redirectUrl.toString();
  };
  const onOAuthClick = async () => {
    setButtonLoading(true);
    const didMockOAuth = await onMockOAuth();
    if (didMockOAuth) {
      setButtonLoading(false);
      return;
    }
    openOAuthWindow(getOAuthUrl());
    setButtonLoading(false);
  };
  return <>
      {loading ? <>
          <InstructionHeader>
            Paycor authentication will continue in a new window. Please make
            sure to allow popups and finish authenticating through Paycor in the
            new window
          </InstructionHeader>
          {sandbox && <InstructionHeader>
              For sandbox, you must log into developers-sandbox.paycor.com and
              launch the dashboard for one of your Paycor Sandbox test accounts
              in another tab.
            </InstructionHeader>}
        </> : <LoaderButton isLoading={loading || buttonLoading} disabled={loading || buttonLoading} onClick={onOAuthClick}>
          Connect
        </LoaderButton>}
      {statusMessage && <StatusMessage>{statusMessage}</StatusMessage>}
    </>;
};
export default withAuthorizeContext(RegisterPaycorOAuth);