import React from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { datadogRum } from '@datadog/browser-rum';
import { OAuthRedirect } from '../../OAuth/OAuthRedirect';
import { getBackendUrl } from '../../../../services/get-backend-url';
type DeelState = {
  sessionKey: string;
};
const BACKEND_ROUTE = getBackendUrl();
const DeelOauthCallback = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const oAuthState = {
    state: queryParams.get('state'),
    code: queryParams.get('code'),
    error: queryParams.get('error')
  };
  const sessionKey = oAuthState.state && (JSON.parse(oAuthState.state || '') as DeelState)?.sessionKey;
  if (sessionKey) {
    datadogRum.setUser({
      id: sessionKey
    });
    datadogRum.addAction('deel_oauth_callback', {
      oAuthState
    });
  }
  const {
    isLoading,
    error: postError
  } = useQuery({
    queryKey: ['deel_oauth_state_update', sessionKey],
    queryFn: () => axios.post(`${BACKEND_ROUTE}/auth/oauth/state/${sessionKey}`, {
      oAuthState
    }),
    refetchOnWindowFocus: false,
    enabled: !!sessionKey,
    onSuccess: () => {
      window.close();
    }
  });
  return <OAuthRedirect isLoading={isLoading} error={postError} data-sentry-element="OAuthRedirect" data-sentry-component="DeelOauthCallback" data-sentry-source-file="DeelOauthCallback.tsx" />;
};
export default DeelOauthCallback;