import React from 'react';
import { MdInfoOutline } from 'react-icons/md';
import styles from './styles.module.scss';
const NoticeMessage = ({
  children
}: {
  children: React.ReactNode;
}) => <div className={`${styles.notice} ${styles.container}`} data-sentry-component="NoticeMessage" data-sentry-source-file="Notice.tsx">
    <div className={styles.icon}>
      <MdInfoOutline size="24px" data-sentry-element="MdInfoOutline" data-sentry-source-file="Notice.tsx" />
    </div>
    <div className={styles.message}>
      <div>{children}</div>
    </div>
  </div>;
export default NoticeMessage;